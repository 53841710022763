import React from 'react'
import * as BS from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, graphql } from "gatsby";
import { Helmet } from 'react-helmet';
// import Bubbles from '../components/Bubbles'
import PieChart from '../components/PieChart'
import BreadHome from '../components/BreadHome'
import { BreadcrumbLite } from '../components/common/BreadcrumbLite'



const InvestorTemplate = (props) => {


  const category = () => {
    if (props.pageContext.category === 'VC') {
      return (
        <Link to={`/investor-type/vc/`}><BS.Badge variant="primary">VC</BS.Badge></Link>
      )
    }
    if (props.pageContext.category === 'angel') {
      return (
        <Link to={`/investor-type/angel/`}><BS.Badge variant="primary">angel</BS.Badge></Link>
      )
    }
    if (props.pageContext.category === 'Corporate VC') {
      return (
        <Link to={`/investor-type/corporate-vc/`}><BS.Badge variant="primary">corporate VC</BS.Badge></Link>
      )
    }
    if (props.pageContext.category === 'Family Office') {
      return (
        <Link to={`/investor-type/family-office/`}><BS.Badge variant="primary">family office</BS.Badge></Link>
      )
    }
    if (props.pageContext.category === 'angel network') {
      return (
        <Link to={`/investor-type/angel-network/`}><BS.Badge variant="primary">angel network</BS.Badge></Link>
      )
    }

  }

  const ifRound = (i) => {
    if (props.pageContext.round[i] === 'pre-seed') {
      return (
        <Link key={i} to={`/round/pre-seed/`}><BS.Badge variant="dark">Pre-Seed</BS.Badge></Link>
      )
    }

    if (props.pageContext.round[i] === 'seed' && props.pageContext.category === 'VC') {
      return (
        <Link key={i} to={`/round/seed/`}><BS.Badge variant="dark">Seed</BS.Badge></Link>
      )
    }

    if (props.pageContext.round[i] === 'series A' && props.pageContext.category === 'VC') {
      return (
        <Link key={i} to={`/round/series-a/`}><BS.Badge variant="dark">Series A</BS.Badge></Link>
      )
    }

    if (props.pageContext.round[i] === 'series B' && props.pageContext.category === 'VC') {
      return (
        <Link key={i} to={`/round/series-b`}><BS.Badge variant="dark">Series B</BS.Badge></Link>
      )
    }

  }

  // const isLogo = () => {
  //   if (props.pageContext.localImage.publicURL !== '/static/ba1252a8ae734065b3e3ef7ed2320180/1.png'
  //     && props.pageContext.imgTWhite === false) {
  //       return (
  //         <BS.Card.Img style={{ margin: '30px 25px', maxWidth: 200}} variant="top" src={props.pageContext.localImage.publicURL} alt={`${props.pageContext.name} logo`} />
  //       )
  //   }
  //   if (props.pageContext.localImage.publicURL !== '/static/ba1252a8ae734065b3e3ef7ed2320180/1.png'
  //     && props.pageContext.imgTWhite === true) {
  //     return (
  //      <BS.Card.Img style={{ margin: '30px 25px', maxWidth: 200, background: '#663499'}} variant="top" src={props.pageContext.localImage.publicURL} alt={`${props.pageContext.name} logo`} />
  //     )
  // }

  // }

  const isLogo2 = () => {
    if (props.pageContext.imageFile !== 'none' && props.pageContext.imgTWhite === false) {
      return (
        <div>
         <img  style={{ margin: '30px 25px', maxWidth: 200}} variant="top" src={`/images/investor/${props.pageContext.imageFile}`} alt={`${props.pageContext.name} logo`}></img>
        </div>
      )
  } 
  
  else if (props.pageContext.imageFile !== 'none' && props.pageContext.imgTWhite === true){
    return (
      <div style={{background: '#663499'}}>
        <img style={{ margin: '30px 25px', maxWidth: 200}} variant="top" src={`/images/investor/${props.pageContext.imageFile}`} alt={`${props.pageContext.name} logo`}></img>
      </div>
    )
  }
}

  

  

  const location = (prop) => {
    if (prop !== '') {
      return (
        <span>- {props.pageContext.city}, {props.pageContext.country}</span>
      )
    }
  }

  const isName = () => {
    if(props.pageContext.description !== 'none'){    
     return (
       <BS.ListGroupItem><b>Name</b> - {props.pageContext.name}</BS.ListGroupItem>
     )}
  }

  const isWebsite = () => {
    if(props.pageContext.description !== 'none'){    
     return (
       <BS.ListGroupItem> <b>Web - </b><a href={props.pageContext.website} target="_blank" rel="noreferrer">{props.pageContext.website}</a></BS.ListGroupItem>
     )}
  }

  const isDescription = () => {
    if(props.pageContext.description !== 'none'){    
     return (
       <BS.ListGroupItem><b>Description</b> - {props.pageContext.description}</BS.ListGroupItem>
     )}
  }
 
  let categoryData = {}
  
  const renderData = (edge, index) => {
    let what = edge.node.businessRef.what
    if(what === 'business software'){
      what = 'B2B software'
    }
    if(what in categoryData){
      categoryData[what] = categoryData[what] + 1
    } else {
      categoryData[what] = 1
    }
   
  }

  const renderInvestment = (edge, index) => {

    const date = new Date(edge.node.date)
    

    return (
      <section>
        <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}><BS.Card.Body>
          <b>{props.pageContext.name}</b> invested in < Link to={`/business/${edge.node.businessRef.name.toLowerCase().replace(/ /g, "-")}/`}>{edge.node.businessRef.name}</Link> during a round worth <b>{edge.node.currency}{new Intl.NumberFormat().format(edge.node.amount)}</b><span style={{ fontSize: 12 }}> - {date.toLocaleDateString()}</span>
      
        </BS.Card.Body>
        </BS.Card>
      </section >
    )
  }

const isTeam = () => {

  if (props.pageContext.team && props.pageContext.team[0].teamName !== 'none'){

    return (
      <section>
  <BS.Card style={{marginTop: 10}}>
  <BS.Card.Header ><h3 style={{ marginTop: 10 }}> Meet the team </h3></BS.Card.Header>
</BS.Card>

<BS.Row className="flex-container" style={{display: 'flex', flexWrap: 'wrap', flexGrow: 4, marginRight: -9, marginLeft: -9}}>

{props.pageContext.team.map(renderTeam)}

</BS.Row>
</section>

    )


  }

}

  const renderTeam = (edge, index) => {

  if(!edge.imageFile.includes('none')){

    return (
  
  
        <BS.Card className='mobile-card' style={{flex: '1 0 20%', margin: 10 }}>
         <BS.Card.Img variant="top" src={`/images/investor/${props.pageContext.name.toLowerCase().replace(/ /g, "-")}/team/${edge.imageFile}`} />
         <BS.Card.Body>
         <BS.Card.Title>{edge.teamName}</BS.Card.Title>
         <BS.Card.Text>
         {edge.teamPosition}
         </BS.Card.Text>
  
         </BS.Card.Body>
         </BS.Card>
  
    )

  }




    // edge.node.team.forEach(teamMember => {


    // })

  }
  



  const renderCategories = (edge, index) => {
    const percentage = `${Math.round((edge[1]/props.data.allFunding.edges.length)*100)}%`
      return (
       <BS.ListGroupItem><b>{edge[0]}</b> - {percentage}</BS.ListGroupItem>
      )
  
  }

  const metaDescription =`Who is behind ${props.pageContext.name}? Find out which companies they have invested in and what is their current market focus?`


  return (
    <section>
      <Helmet>
        <html lang="en" />
        <title>Should you work with {props.pageContext.name}? Read this first!</title>
        <meta name="description" content={metaDescription} />

      </Helmet>
  
      <BS.Container style={{ paddingLeft: 0, paddingRight: 11, maxWidth: 1240 }}>
      <BreadcrumbLite>
      <BreadHome/>
        <BS.Breadcrumb.Item> <Link to={`/`}>Data</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item> <Link to={`/investor/`}>Investors</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item active>{props.pageContext.name}</BS.Breadcrumb.Item>
      </BreadcrumbLite>

        <BS.Row style={{display: 'block'}}>
          <BS.Col>
            <h1>{props.pageContext.name} {category()}</h1>
            <h2 style={{color: '#34383b', fontWeight: 300, marginBottom: 25}}>Investments in the UK by market, targeted sectors and preferred funding rounds</h2>
          </BS.Col>
        </BS.Row>
               
    
        <BS.Row>
          <BS.Col sm={4}>
          <BS.Card >
                
                {isLogo2()}
                
                <BS.ListGroup className="list-group-flush">
                  
                 {isName()}
                 {isWebsite()}
                 {isDescription()}

                </BS.ListGroup>
                </BS.Card>

            <BS.Card style={{ marginTop: 5, marginBottom: 5 }}><BS.Card.Body>
              <b>Investor Type - </b> {category()}
            </BS.Card.Body>
            </BS.Card>
            <BS.Card style={{ marginTop: 5, marginBottom: 5 }}><BS.Card.Body>
              <b>Preferred rounds - </b> {ifRound(0)} {ifRound(1)} {ifRound(2)} {ifRound(3)}
            </BS.Card.Body>
            </BS.Card>
            <BS.Card style={{ marginTop: 5, marginBottom: 5 }}><BS.Card.Body>
              <b>Location</b> {location(props.pageContext.city)}
            </BS.Card.Body>
            </BS.Card>
          </BS.Col>


          <BS.Col sm={8}>

              <BS.Card style={{marginTop: 0}}>
                <BS.Card.Header ><h3 style={{ marginTop: 10 }}> Investments by industry </h3></BS.Card.Header>
              </BS.Card>

              <BS.Row>
              <BS.Col sm={12}>
              {props.data.allFunding.edges.map(renderData)}
                <BS.Card style={{marginTop: 5}}>
                  <BS.Card.Body>
                    <BS.Row>
                      <BS.Col sm={8}>
                        <div id='bubbles' style={{ marginTop: 30 }}>
                          <PieChart m={categoryData}/>
                        </div>
                      </BS.Col>
                      <BS.Col>
                        {Object.entries(categoryData).map(renderCategories)}
                      </BS.Col>
                    </BS.Row>
                  </BS.Card.Body>
                </BS.Card>
              </BS.Col>
              </BS.Row>

              <BS.Card style={{marginTop: 10}}>
                <BS.Card.Header ><h3 style={{ marginTop: 10 }}> Number of investments: {props.data.allFunding.totalCount} </h3></BS.Card.Header>
              </BS.Card>
              {props.data.allFunding.edges.map(renderInvestment)}

              {/* {isTeam()} */}

    
        </BS.Col>
          
          
        </BS.Row>
        </BS.Container>
   



    </section >
  )
}

export const query = graphql`




query IndexQuery2 ($name: String!)
{
  allFunding(filter: {businessRef: {country: {eq: "UK"}}, investors: {eq: $name}}, sort: {fields: date, order: DESC}) {
    edges {
      node {
        date
        amount
        currency
        businessRef {
          ... on Business {
          id
          name
          website
          what
          }
        }
      }
    }
    totalCount
  }
  allInvestor(filter: {name: {eq: $name}}) {
    edges {
      node {
        ai
        name
        biotech
        businessSoftware
        cannabis
        cars
        cyberSecurity
        data
        drone
        ecommerce
        edtech
        energy
        foodtech
        fintech
        freighttech
        gaming
        hardware
        hrtech
        healthtech
        healthtechuk
        insurtech
        martech
        proptech
        robotics
        travel
        vr
        imageFile
      }
    }
  }
}
`

export default InvestorTemplate